<template>
    <div class="auth-layout flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden px-3">
        <div class="auth-wrapper bg-white border-round-lg p-2 lg:p-0">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'authentication-layout',
    }
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables';
.auth-layout {
    height: 100vh;
    background-color: $authBackground;
    background-image: url('~@/assets/images/backgrounds/auth.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .auth-wrapper {
        width: 440px;
    }
    @media (max-width: 575px) {
        .auth-wrapper {
            width: 100%;
        }
    }
}
</style>